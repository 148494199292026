
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import {
  Header,
  SingleCheckbox,
  QuestionWrapper,
} from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    SingleCheckbox,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 1;
    const router = useRouter();

    const title = "What would you like to do today?";
    const options = [
      {
        label: "Starting birth control for the first time",
        value: "Starting birth control for the first time",
      },
      {
        label: "Refilling birth control that I'm currently on",
        value: "Refilling birth control that I'm currently on",
      },
      {
        label: "Restarting birth control",
        value: "Restarting birth control",
      },
      {
        label: "Changing birth control due to cost",
        value: "Changing birth control due to cost",
      },
      {
        label: "Changing birth control due to side effects",
        value: "Changing birth control due to side effects",
      },
      {
        label: "Other",
        value: "Other",
      },
    ];

    const onAnswered = () => {
      router.push(String(questionNumber + 1));
    };

    return {
      options,
      onAnswered,
      questionNumber,
      title,
    };
  },
});
